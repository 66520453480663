/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { AppLoadsActions, UserProfileActions } from '@dpa-shared-merlot/store';
import { DashboardActions, TrendComposerService } from '@ws1c/intelligence-core';
import { getAppFromRouteParams, StandardWidgetSubtype, Trend, TrendResult } from '@ws1c/intelligence-models';

export const VISIBLE_WIDGET_SUBTYPES = [
  StandardWidgetSubtype.USER_PROFILE_DEVICES_BY_USER,
  StandardWidgetSubtype.USER_PROFILE_DEVICE_DETAILS,
];

/**
 * Resolves the app details user profile
 * @param {ActivatedRouteSnapshot} route
 * @export
 * @returns {Observable<string[]>}
 */
export function appDetailsUserProfileResolver(route: ActivatedRouteSnapshot): Observable<string[]> {
  const store = inject(Store);
  const tcs = inject(TrendComposerService);
  store.dispatch(DashboardActions.removeVisibleWidgetSubtypes({ widgetSubtypes: VISIBLE_WIDGET_SUBTYPES }));
  store.dispatch(AppLoadsActions.selectApp({ app: getAppFromRouteParams(route.queryParams, route.params) }));
  store.dispatch(UserProfileActions.setAppDetailUser({ user: route.params?.user }));
  store.dispatch(UserProfileActions.setAppDetailUserProfileTab({ tab: undefined }));
  store.dispatch(UserProfileActions.loadAppDetailUserProfileDashboard());
  store.dispatch(DashboardActions.addVisibleWidgetSubtypes({ widgetSubtypes: VISIBLE_WIDGET_SUBTYPES }));
  return tcs.getTrend$(StandardWidgetSubtype.USER_PROFILE_DEVICES_BY_USER).pipe(
    map((trend: Trend) => trend?.trendResults?.map((result: TrendResult) => result.bucketingAttributes[0]?.value)),
    filter((devices: string[]) => !!devices?.length),
    take(1),
  );
}
